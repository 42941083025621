const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      profile: "me",
      login: "sessions",
    },
    transcriber: {
      login: "/transcriber/sessions",
      transcriber: "/transcribers",
      transcriberCases: "/transcriberCases",
      transcriberCasesStats: "/transcriberCasesStats",
    },
    case: {
      updateLiveTranscriptionData: "/updateLiveTranscriptionData",
      getLiveTranscriptionData: "/getLiveTranscriptionData",
      getLiveTranscription: "/getLiveTranscription",
      updateTranscriptionData: "/updateTranscriptionData",
      getMeetingAudioTranscript: "/getMeetingAudioTranscript",
      updateAudioTranscriptData: "/updateAudioTranscriptData",
    },
    transcription: {
      updateXExamTranscript: "/updateXExamTranscript",
      updateXExamTranscriptDoc: "/updateXExamTranscriptDoc",
      getTranscriptionStatus: "/getTranscriptionStatus",
    },
    meeting: {
      mettingcase: "/getMeetingDet",
    },
    submit: {
      submitLiveTranscription: "/submitLiveTranscription",
      submitVideoRecTranscription: "/submitVideoRecTranscription",
      submitAudioRecTranscription: "/submitAudioRecTranscription",
    },
    s3: "s3-url",
    systemConfig: "system-configs",
    systemConfigAgreement: "/mediator/system-configs",
    user: {
      index: "casemanager/users",
    },
  },
};

export default ApiConfig;
